.page {
  display: flex;
  flex-direction: row;
}

.fleche1 {
  fill: black;
  stroke-width: 0px;
}

.fleche2 {
  fill: none;
  stroke: black;
  stroke-miterlimit: 10;
}

.fleches {
  display: flex;
  justify-content: center;
}

.pour_aller_plus_loin_block {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  border: 1px solid #10345a;
  border-radius: 9px;
  padding: 10px 50px;
  color: #10345a;
}

.pour_aller_plus_loin_block2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
}

.schema_global_composant {
  display: flex;
  min-width: 350px;
  width: 80%;
  background-color: white;
  margin-bottom: 0px;
  flex-direction: column;
  padding: 40px 40px 100px 40px;
  border-radius: 0px;
  box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.267);
  min-height: auto;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
}

.schema_main_composent {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: auto;
  justify-content: center;
}

.schema_main_title {
  text-align: center;
  margin: 15px 15px 25px 15px;
  font-size: 30px;
  font-weight: 500;
}

.schema_option_block {
  font-family: roboto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
}

.schema_option_block_result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}

.schema_option_button {
  display: flex;
  text-align: center;
  margin: 10px;
  width: 250px;
  height: 50px;
  background-color: #f2c879;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* box-shadow: 1px 1px 2px 1px #0000006e; */
  padding: 10px;
}

.schema_option_button:hover {
  display: flex;
  text-align: center;
  margin: 10px;
  width: 250px;
  height: 50px;
  background-color: #b56038;
  color: white;
  border-radius: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* box-shadow: 1px 1px 2px 1px #0000006e; */
  padding: 10px;
}

.schema_option_button.selected {
  background-color: #b56038;
  color: white;
}

.schema_page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schema_title {
  margin: 10px;
  font-size: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;
  justify-content: center;
}

.schema_result_title {
  margin: 10px;
  font-size: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: start;
  justify-content: flex-start;
}

.schema-selected-option-rappel-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.schema-selected-options-results {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0px;
}

.schema-selected-options-results-block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.schema-selected-options-results-button {
  background-color: #154f5c;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 1px 1px 3px grey;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 250px;
  min-width: 250px;
}

.schema-selected-options-results-button:hover {
  background-color: #3b889a;
  color: #000000;
}

.schema-selected-options-results-button .schema_result_title {
  font-size: 20px;
}

.schema-selected-options-results-button-tva {
  color: #ffffff;
  background-color: #b56038;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 1px 1px 3px grey;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 250px;
  min-width: 250px;
  min-height: 35px;
  /* margin-bottom: 20px; */
}

.schema-selected-options-results-button-other {
  color: #ffffff;
  background-color: #b56038;
  padding: 10px;
  border-radius: 7px;
  box-shadow: 1px 1px 3px grey;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 250px;
  min-width: 250px;
  min-height: 40px;
  /* margin-bottom: 20px; */
}

.schema-selected-options-results-button-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.schema-selected-options-results-button-block-tva {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
}

.schema-selected-options-results-tva {
  padding: 10px 10px 10px 0px;
  border-radius: 7px;
  text-align: justify;
  margin-top: 0px;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.result_title_tva {
  font-family: "Roboto";
  margin-top: 10px;
  font-size: 18px;
  color: #10345a;
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.result_title_DE_block {
  font-family: "Roboto";
  margin: 20px 0px 20px 0px;
  color: #10345a;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-direction: column;
  border: 1px solid #6f2563;
  border-radius: 9px;
  padding: 20px 30px;
  width: 70vw;
  overflow-y: scroll;
}

.result_title_DE_sous_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result_title_DE {
  font-family: "Roboto";
  font-size: 18px;
  color: #10345a;
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 20px;
}

.result_subtitle_DE {
  font-family: "Roboto";
  font-size: 15px;
  color: #10345a;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.rappel_title {
  font-family: "Roboto";
  margin-top: 30px;
  font-size: 15px;
  color: #0b2540;
  font-style: italic;
}

.picto_tva {
  height: 40px;
}
.schema-selected-options-results-buttonPDF {
  background-color: #183d64;
  padding: 10px;
  border-radius: 9px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 300px;
  text-align: center;
  color: #ffffff;
}

.schema-selected-options-results-buttonPDF a {
  color: #ffffff;
}

.schema-selected-options-results-buttonPDF:hover {
  background-color: #7aa3cf;
}

.chema-selected-options-results-buttonPDF-box {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}
a:hover {
  color: #000000;
}

/* 
@media (min-width: 580px) and (max-width: 1000px) {
  .schema_main_composent {
    display: flex;
    min-width: 350px;
    background-color: white;
    margin-bottom: 150px;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 80px 20px;
    border-radius: 7px;
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.267);
    min-height: 400px;
  }

  .step-progress-bar {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    width: 80%;
    max-width: 450px;
  }
  .schema-selected-option-rappel-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 15px;
  }
  .schema-selected-options-results-button-tva {
    padding: 10px;
    border-radius: 7px;
    box-shadow: 1px 1px 3px grey;
    text-align: justify;
    display: flex;
    align-items: center;
    min-width: 400px;
  }
} */

@media (max-width: 580px) {
  .schema_main_title {
    text-align: center;
    margin: 15px;
    font-size: 30px;
    font-weight: 500;
  }

  .schema_option_button {
    display: flex;
    text-align: center;
    margin: 10px;
    width: 300px;
    height: 50px;
    background-color: #8cb2db;
    border-radius: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 1px 1px 2px 1px #0000006e;
    padding: 10px;
  }

  .schema_main_composent {
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    min-width: 250px;
    background-color: white;
    margin-bottom: 150px;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 50px 20px;
    border-radius: 7px;
    box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.267);
    min-height: 400px;
  }

  .step-progress-bar {
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    width: 80%;
  }

  .progressbar_main_composent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .schema-selected-option-rappel-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 15px;
  }

  .schema-selected-options-results-tva {
    padding: 10px;
    border-radius: 7px;
    text-align: justify;
    margin-top: 0px;
    color: #205186;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .result_title_DE {
    font-family: "Roboto";
    margin-top: 10px;
    font-size: 18px;
    color: #10345a;
    font-weight: 900;
  }

  .schema-selected-options-results-button-tva {
    padding: 10px;
    border-radius: 7px;
    box-shadow: 1px 1px 3px grey;
    text-align: justify;
    display: flex;
    align-items: center;
    min-width: 250px;
  }

  .picto_tva {
    height: 25px;
  }
}
