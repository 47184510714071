.slider-container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
}

.slider {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.slider-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 250px;
  flex-shrink: 0;
  margin-right: 20px;
  scroll-snap-align: start;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.slider-image {
  width: 200%;
  height: auto;
  display: block;
}

.slider-caption {
  position: absolute;
  margin: auto;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 5;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.dots {
  text-align: center;
  margin-top: 15px;
}

.dot {
  height: 10px;
  width: 15px;
  margin: 0 5px;
  background-color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  width: 25px;
  background-color: #041f2b;
}
